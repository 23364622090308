.Dialog-background {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.Dialog {
  width: 480px;
  padding: 0;
  z-index: 30;
  background-color: rgb(250, 250, 250);
  border-radius: 8px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}

.Dialog-main {
  padding: 0 40px 20px 40px;
}

.Dialog-title {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
}

.Dialog-message {
  margin: 20px 0;
}

.Dialog-button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid rgb(224, 224, 224);
}

.Dialog-button_container button:first-child {
  margin-right: 12px;
}
