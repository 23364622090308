.PlaceIndexPage-container {
  margin-top: 24px;
  padding: 12px 24px;
  width: max-content;
  min-width: 480px;
}

.PlaceIndexPage-button_container {
  margin-top: 10px;
}

.PlaceIndexPage-button_container:nth-child(1) {
  margin-top: 40px;
}

.PlaceIndexPage-picture_layer_color {
  display: flex;
}

.PlaceIndexPage-picture_layer_color_box {
  width: 18px;
  height: 18px;
  border: 1px #000000 solid;
  margin-right: 8px;
}