.TableWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Row {
  display: flex;
  width: 40rem;
  max-width: 80%;
  align-items: center;
  margin: 1rem 0;
}

.Cell {
  width: 0;
  flex-grow: 1;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
