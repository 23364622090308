.Loading-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.Loading-circle {
  width: 14px;
  opacity: 0;
  margin-right: 8px;
  animation-name: expansion;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.Loading-circle:nth-child(1) {
  animation-delay: 0s;
}

.Loading-circle:nth-child(2) {
  animation-delay: 0.1s;
}

.Loading-circle:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes expansion {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.Loading-circle:last-child {
  margin-right: 0;
}
