.MyPage-container {
  width: 100%;
}

.MyPage-label {
  display: block;
}

.MyPage-name {
  margin: 10px 0 0 0;
}

.MyPage-screen_id {
  margin-top: 30px;
}

.MyPage-screen_id,
.MyPage-department,
.MyPage-created {
  margin-bottom: 24px;
}

.MyPage-change_password_button {
  margin: 40px 0 0 0;
}

.MyPage-logout_button {
  margin-top: 10px;
}

.MyPage-label {
  color: rgb(54, 54, 54);
  font-weight: bold;
  margin: 2px;
}

.MyPage-name_data {
  font-size: 22px;
}
.MyPage-name_data .name {
  font-weight: bold;
}

.MyPage-data {
  margin-left: 8px;
}
