.ImageSelectOption-container {
  padding: 14px;
  margin-right: 10px;
  border: 1px solid rgb(170, 170, 170);
  background-color: #fff;
}

.ImageSelectOption-selected {
  padding: 10px;
  border: 5px solid rgb(39, 39, 39);
}

.ImageSelectOption-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
