.App-content {
  margin-top: 50px;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.App-title {
  font-family: 'Mallanna', sans-serif;
}

.App-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-icon img {
  width: 24px;
  height: 24px;
}

.App-button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0), 0 2px 2px rgba(0, 0, 0, 0.075); */
  line-height: 16px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 8px;
}

.App-button_color_gray {
  color: rgb(63, 63, 63);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(219, 219, 219);
}

.App-button_color_green {
  color: #fff;
  background-color: rgb(76, 204, 119);
  border: 1px solid rgb(90, 167, 90);
}

.App-button_color_red {
  color: rgb(232, 232, 232);
  background-color: rgb(205, 93, 93);
  border: 1px solid rgb(183, 84, 84);
}

.App-button_mini {
  line-height: 14px;
  font-size: 14px;
  padding: 8px 18px;
}

.App-button:hover {
  opacity: 0.8;
}

.App-button:disabled,
.App-button[disabled],
.App-button:disabled:hover,
.App-button[disabled]:hover {
  opacity: 1;
  color: #666666;
  background-color: #cccccc;
  border: 1px solid #999999;
}

.App-link_button {
  display: inline-block;
  background-color: rgb(59, 112, 224);
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 4px;
}

.App-content {
  display: flex;
  flex-direction: row;
  min-height: 680px;
  margin: 50px auto 0 auto;
  padding: 0;
}

.App-navigator {
  display: flex;
  width: 320px;
  min-width: 320px;
}

.App-main {
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  padding: 30px 60px;
}

.App-table {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 10px 0;
  border: 1px solid #000;
}

.App-table th {
  padding: 16px 20px;
  background-color: #fff;
  border-bottom: 2px solid #000;
}

.App-table td {
  padding: 12px 20px;
}

.App-table tr:nth-child(even) {
  background: #f5f5f5;
}

td.App-table_button_cell {
  padding: 0 8px 0 8px;
}

.App-label {
  color: rgb(82, 79, 79);
  font-weight: bold;
  display: block;
}

.App-form {
  padding: 30px 50px;
}

input {
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgb(153, 153, 153);
}

h1 {
  color: rgb(44, 44, 44);
}

h3 {
  font-weight: bold;
  padding: 0 0 4px 8px;
  border-bottom: 1px solid rgb(200, 200, 200);
  color:rgb(44, 44, 44);
}

select {
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgb(153, 153, 153);
}
