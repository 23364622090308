.LoginPage-title {
  font-size: 35px;
  color: rgb(63, 64, 77);
  text-align: center;
  margin: 0;
}

.LoginPage-login_container {
  max-width: 300px;
  border: 1px solid #000;
  margin: 90px auto 0 auto;
  padding: 50px;
  background-color: #fff;
}

.LoginPage-title_container {
  margin: 0 0 30px 0;
}

.LoginPage-id_container {
  margin-top: 18px;
}
.LoginPage-password_container {
  margin-top: 18px;
}
.LoginPage-login_button_container {
  margin-top: 30px;
}

.LoginPage-login_container label {
  display: block;
}

.LoginPage-login_id_input,
.LoginPage-login_password_input {
  width: 100%;
}

.LoginPage-login_button {
  width: 100%;
}
