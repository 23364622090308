.AdminPage-container {
  width: 100%;
}

.AdminPage-authority_col {
  text-align: center;
}

.LinkToDeviceStatus {
  margin-left: 2rem;
}
