.PlaceDxfListPage-container {
  padding-top: 24px;
}

.PlaceDxfListPage-preview_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.PlaceDxfListPage-preview_background {
  width: 780px;
  height: 420px;
  margin: 120px auto 0 auto;
  background-color: white;
}

.PlaceDxfListPage-prewview_svg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
