.AuthorityLabel-admin,
.AuthorityLabel-master_admin {
  font-size: 14px;
  border-radius: 5px;
  padding: 2px 10px;
}

.AuthorityLabel-admin {
  color: rgb(133, 69, 69);
  border: 1px solid rgb(133, 69, 69);
}

.AuthorityLabel-master_admin {
  color: rgb(110, 76, 128);
  border: 1px solid rgb(110, 76, 128);
}
