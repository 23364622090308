.PlaceCreateCodePage-additional_field_options_list {
  list-style: none;
}

.PlaceCreateCodePage-image_option {
  display: inline-block;
}

.PlaceCreateCodePage-image_option img {
  width: 64px;
  display: block;
}
