.PlaceListPage-container {
  width: 100%;
}

.PlaceListPage-tag_container {
  padding: 20px 0 2px 0;
}

.PlaceListPage-tag_operations > .PlaceListPage-tag_operation_button {
  display: inline-block;
  margin-right: 10px;
}

.PlaceListPage-tag_operations > .PlaceListPage-tag_operation_button:last-child {
  margin-right: 0px;
}