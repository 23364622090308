.PlaceCreatePage-form {
  margin: 0 18px;
}

.PlaceCreatePage-create_button {
  margin-top: 18px;
  width: 120px;
}

.PlaceCreatePage-client_select {
  width: 300px;
}

.PlaceCreatePage-place_name_input {
  width: 380px;
}

.PlaceCreatePage-place_chief_name_input {
  width: 240px;
}

.PlaceCreatePage-place_tag_input {
  height: 34px;
}

.PlaceCreatePage-place_tag_container {
  padding: 16px 0;
}

.PlaceCreatePage-place_tag_button {
  margin-top: 8px;
}

.PlaceCreatePage-input_container {
  margin-top: 20px;
}

.PlaceCreatePage-button_container {
  margin-top: 30px;
}
