.PlaceCodeAndSortPage-image {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
}

.PlaceCodeAndSortPage-image img {
  width: 48px;
  display: block;
  margin: auto;
}

.PlaceCodeAndSortPage-disabled-config {
  background-color: rgb(124, 124, 124) !important;
}