.Navigator-container {
  width: 100%;
  padding-top: 30px;
  background-color: #fff;
  overflow: hidden;
  border-right: 1px solid rgb(240, 240, 240);
}

.Navigator-link_icon {
  height: 24px;
  width: 24px;
}

.Navigator-link_icon img {
  width: 100%;
}

.Navigator-link_text {
  display: flex;
  align-items: center;
  margin-left: 26px;
  color: rgb(48, 48, 48);
}

.Navigator-container ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.Navigator-container li {
  display: flex;
  padding: 8px 30px;
  align-items: center;
  list-style-type: none;
  height: 40px;
  padding-left: 35px;
}

.Navigator-container a {
  text-decoration: none;
}

.Navigator-container li.Navigator-selected {
  background-color: rgb(240, 240, 240);
  font-weight: bold;
}
