.DrawingObjectInput-text_input {
  width: 100px;
}
.DrawingObjectInput-number_input {
  width: 80px;
}

.DrawingObjectInput-image_option {
  margin-top: 10px;
  display: block;
}

.DrawingObjectInput-image_option img {
  width: 48px;
}
