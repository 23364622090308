.ToastContainer-container {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: right;
  align-items: flex-end;
  z-index: 20;
}

.Toast-box {
  width: 320px;
  padding: 16px;
  background-color: rgb(255, 255, 255);
  margin: 0 0 10px 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  animation-name: show_toast;
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.Toast-box.info {
  border: 1px solid rgb(114, 167, 207);
}

.Toast-box.error {
  border: 1px solid rgb(224, 79, 95);
}

.Toast-box.success {
  border: 1px solid rgb(50, 190, 166);
}

@keyframes show_toast {
  0% {
    margin-right: 0px;
    opacity: 0;
  }
  100% {
    margin-right: 10px;
    opacity: 1;
  }
}

.Toast-icon {
  width: 22px;
  height: 22px;
}

.Toast-delete_icon_container {
  margin-left: auto;
  display: flex;
  align-items: center;

  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.Toast-delete_icon {
  margin-left: auto;
  width: 12px;
  opacity: 0.2;
}

.Toast-message {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.info .Toast-message {
  color: rgb(119, 119, 119);
}

.error .Toast-message {
  color: rgb(160, 108, 108);
}

.success .Toast-message {
  color: rgb(109, 129, 111);
}
