.PlaceCreateCodeAndSortPage-color_container {
  width: 320px;
}

.PlaceCreateCodeAndSortPage-color_picker {
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid rgb(167, 167, 167) !important;
}

.PlaceCreateCodeAndSortPage-text_input {
  width: 100px;
}

.PlaceCreateCodeAndSortPage-number_input {
  width: 80px;
}

.PlaceCreateCodeAndSortPage-image_select {
  display: flex;
  flex-direction: row;
}

.PlaceCreateCodeAndSortPage-image_option {
  display: flex;
  width: 100px;
}

.PlaceCreateCodeAndSortPage-image_option img {
  width: 100%;
  margin: auto;
  display: block;
}

.PlaceCreateCodeAndSortPage-input_container {
  margin-top: 20px;
}