.AdminCreateAccountPage-screen_id_input {
  width: 300px;
}

.AdminCreateAccountPage-name_input {
  width: 300px;
}

.AdminCreateAccountPage-department_input {
  width: 300px;
}

.AdminCreateAccountPage-department_input {
  width: 300px;
}

.AdminCreateAccountPage-password_input {
  width: 240px;
}
