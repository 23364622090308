.PlaceTag-button {
  background-color: rgb(226, 226, 226);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: none;
  text-align: center;
  /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0), 0 2px 2px rgba(0, 0, 0, 0.075); */
  line-height: 16px;
  text-decoration: none;
  border-radius: 8px;
}

.PlaceTag-button:hover {
  opacity: 0.8;
}

.PlaceTag-button.selected {
  background-color: rgb(208, 236, 255);
}

.PlaceTag-contents {
  margin-left: auto;
  display: flex;
  align-items: center;

  height: 23px;

  background: none;
	color: inherit;
	border: none;
	padding: 0 0 0 8px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.PlaceTag-delete_icon {
  margin: 0;
  padding: 0;
  width: 12px;
  opacity: 0.2;
}

.PlaceTag-delete_button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  width: 23px;
  height: 23px;
  padding: 5px;
}

.PlaceTag-delete_button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}


.PlaceTag-message {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  padding: 0;
  margin: 0 8px 0 0;
}
